import React, { useEffect, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { tabsType } from "../../../Types/Types";
import CustomTabPanel from "./CustomTabPanel";
import { useTranslation } from "react-i18next";

type Props = {
  tabs: tabsType;
};

const MainTabs = ({ tabs }: Props) => {
  const { t, i18n } = useTranslation();
  //tabs state
  const [value, setValue] = useState("1");
  // console.log(tabs?.LIVE?.episodes);
  
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  useEffect(() => {
    if (tabs?.LIVE) {
      setValue("1");
    } else if (tabs?.DOWNLOAD) {
      setValue("2");
    } else if (tabs?.DVD) {
      setValue("3");
    } else {
      setValue("4");
    }
  }, [tabs]);
  //end of snippet

  // //open first tab with content at first render
  // const openFirstTabWithContent = (tabs: tabType[]) => {
  //   const firstTabWithContent = tabs.find((tab) => tab.is_empty === false);
  //   return firstTabWithContent?.label || " ";
  // };
  // useEffect(() => {
  //   setValue(openFirstTabWithContent(tabs));
  // }, []);
  //end of snippet
  // console.log('tabs',tabs?.LIVE);
  
  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "48px",
      }}
    >
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="secondary tabs example"
        sx={{
          width: "100%",
          display: "flex",
          "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
          },
          "& .MuiTab-root": {
            color: "white",
            fontSize: {
              mobile: "18px",
              md: "24px",
            },
            "&.Mui-disabled": {
              color: "rgb(97 93 93)",
            },
            "&.Mui-selected": {
              color: "#c89dff",
            },
          },
        }}
      >
        <Tab
          key={"Tab-live"}
          label={t("purchases.tabs.online")}
          value={"1"}
          disabled={!tabs.LIVE}
        />

        <Tab
          key={"Tab-download"}
          label={t("purchases.tabs.download")}
          value={"2"}
          disabled={!tabs.DOWNLOAD}
        />
        <Tab
          key={"Tab-dvd"}
          label={t("purchases.tabs.dvd")}
          value={"3"}
          disabled={!tabs.DVD}
        />
        <Tab
          key={"Tab-storage"}
          label={t("purchases.tabs.storage")}
          value={"4"}
          disabled={!tabs.STORABLE}
        />
      </Tabs>
        {( value === "1" ||  value === "2") && (
          <Box sx={{
            display: "flex",
            gap: "4px",
            flexWrap: "wrap",
            "@media (max-width: 576px)": {
              fontSize: "14px",
            },
          }} >
            <Typography 
              sx={{
                fontSize: "2em",
                color: "#FFAD0A",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {t("purchases.purchases-cart.possessions.note")}
            </Typography>
            <Typography 
              sx={{
                fontSize: "2em",
                color: "#ffffff",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
            {" "+t("purchases.purchases-cart.possessions.remaining")}
            </Typography>
            <Typography 
              sx={{
                fontSize: "2em",
                color: "#FFAD0A",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {
                value === "1" && (i18n.language === 'ar' ? 'ال'+t("purchases.tabs.online"):t("purchases.tabs.online"))
              }
              {
                value === "2" && (i18n.language === 'ar' ? 'ال'+t("purchases.tabs.download"):t("purchases.tabs.download"))
              }
              {" "+t("purchases.purchases-cart.possessions.valid_for")}
            </Typography>
            <Typography 
              sx={{
                fontSize: "2em",
                color: "#FFAD0A",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {tabs.remaining_days}
            </Typography>
            <Typography 
              sx={{
                fontSize: "2em",
                color: "#ffffff",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {tabs.remaining_days !== 1 ? " "+t("purchases.purchases-cart.possessions.days") : " "+t("purchases.purchases-cart.possessions.day")} 
            </Typography>
          </Box>
        )}
      <Box
        sx={{
          width: "100%",
        }}
      >
        {/* online Panel */}
        <CustomTabPanel
          value={value}
          type="1"
          label={t("purchases.tabs.online")}
          singleItems={(tabs?.LIVE?.movie)?.concat(tabs?.LIVE?.episodes as any) || tabs?.LIVE?.episodes || tabs?.LIVE?.movie}
          seriesItems={tabs?.LIVE?.series}
          seasonItems={tabs?.LIVE?.season}
        />
        {/* download Panel */}
        <CustomTabPanel
          value={value}
          type="2"
          label={t("purchases.tabs.download")}
          // singleItems={tabs?.DOWNLOAD?.movie || tabs?.DOWNLOAD?.episode }
          singleItems={(tabs?.DOWNLOAD?.movie)?.concat(tabs?.DOWNLOAD?.episodes as any) || tabs?.DOWNLOAD?.episodes || tabs?.DOWNLOAD?.movie}
          seriesItems={tabs?.DOWNLOAD?.series}
          seasonItems={tabs?.DOWNLOAD?.season}
        />

        {/* dvd Panel */}
        <CustomTabPanel
          value={value}
          type="3"
          label={t("purchases.tabs.dvd")}
          singleItems={tabs?.DVD?.movie}
          seriesItems={tabs?.DVD?.series}
          seasonItems={tabs?.DVD?.season}
        />
        {/* storage Panel */}
        <CustomTabPanel
          value={value}
          type="4"
          label={t("purchases.tabs.storage")}
          singleItems={tabs?.STORABLE?.movie}
          seriesItems={tabs?.STORABLE?.series}
          seasonItems={tabs?.STORABLE?.season}
        />
      </Box>
    </Box>
  );
};

export default React.memo(MainTabs);
