import { MuiTelInput } from "mui-tel-input";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { isValidNumber } from "libphonenumber-js";

type Props = {
  control: any;
  name: string;
  label?: string;
  background?: string;
};

const PhoneInput = ({
  control,
  name,
  label,
  background = "#CEAFF542",
}: Props) => {
  const { t } = useTranslation();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <MuiTelInput
          // onlyCountries={[
          //   "EG",
          //   "KW",
          //   "SA",
          //   "OM",
          //   "AE",
          //   "QA",
          //   "BH",
          //   "JO",
          //   "LB",
          //   "LY",
          //   "TN",
          //   "DZ",
          // ]}
          variant="outlined"
          label={label}
          {...field}
          defaultCountry="KW"
          //   onlyCountries={["FR"]}
          helperText={fieldState.invalid ? t("phoneValidation") : ""}
          error={fieldState.invalid}
          sx={{
            direction: "rtl",
            borderRadius: "12px",
            color: "white",
            "& img": {
              width: "45px",
            },
            "& .MuiOutlinedInput-root": {
              background: background,
              color: "white",
              borderRadius: "12px",
              // border: "1px solid black",

              "&:hover": {
                borderColor: "rgb(88, 135, 233, 0.4)",
              },
            },
            "& .MuiInputBase-input": {
              padding: "26px 0px",
              textAlign: i18n.language === "ar" ? "end" : "start",
              "@media (max-width: 600px)": {
                padding: "16px 0px",
              },
              "& .MuiIconButton-root": {
                width: "30px",
              },
            },
            "& .MuiInputLabel-root": {
              color: "#ECECEC",
              // transform: "translate(14px, 26px) scale(1)",
            },
            "& .MuiInputLabel-root.Mui-focused": {
              color: "#81CEFC",
              // transform: "translate(14px, -10px) scale(0.75)",
            },
            "&.Mui-disabled": {
              color: "green",
            },
            "& .Mui-disabled:before": {
              borderBottomStyle: "solid",
            },
          }}
        />
      )}
    />
  );
};

export default PhoneInput;
