import { styled } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import fatoorahImage from "../../../Assets/Images/fatoorah.png";
import { useDispatch } from "react-redux";
import { cartActions } from "../../../store/cart-slice";

export default function OnlinePaymentMethods() {
  const paymentMethods = [
    // {
    //   id: 2,
    //   name: "myFatoorah",
    //   image: fatoorahImage,
    // },
    {
      id: 3,
      name: "Paypal",
      image:
        "https://www.paypalobjects.com/digitalassets/c/website/logo/full-text/pp_fc_hl.svg",
    },
    {
      id: 4,
      name: "UPayment",
      image: "https://upayments.com/en/wp-content/uploads/sites/4/2021/01/UPayments-Logo.svg"
    }
  ];
  const dispatch = useDispatch();
  const handleChangePaymentMethod = (id: number) => {
    dispatch(cartActions.setPaymentMethod(id));
  };
  return (
    <FormControl sx={{ width: "100%" }}>
      <StyledRadioGroup
        aria-labelledby="radio-buttons-group-label"
        defaultValue={4}
        name="radio-buttons-group"
      >
        {paymentMethods.map((method) => (
          <FormControlLabel
            sx={{
              "& .MuiFormControlLabel-label": {
                display: "flex",
                alignItems: "center",
              },
            }}
            onChange={() => {
              handleChangePaymentMethod(method.id);
            }}
            key={method.id}
            value={method.id}
            control={<Radio color="secondary" />}
            label={<StyledImage src={method.image} alt={method.name} />}
          />
        ))}
      </StyledRadioGroup>
    </FormControl>
  );
}

const StyledRadioGroup = styled(RadioGroup)({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-around",
});
const StyledImage = styled("img")({
  backgroundColor: "white",
  width: "130px",
  height: "40px",
  padding: "4px",
  objectFit: "contain",
  borderRadius: "12px",
  boxShadow: "0px 0px 12px #0000003B",
  cursor: "pointer",
  "&:hover": {
    boxShadow: "0px 0px 12px #0000003B",
  },
  "@media (max-width: 1024px)": {
    width: "100px",
    height: "30px",
  },
});
