import {
  Box,
  Button,
  CircularProgress,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";

import { createData } from "../../../../Services/DataServices";
import {
  displayToastErrorApi,
  displayToastSuccess,
} from "../../../../Helpers/toastHelper";
import { useTranslation } from "react-i18next";

const FollowUs = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const subscribe = async (mail: string) => {
    const formData = new FormData();
    formData.append("email", mail);
    try {
      setLoading(true);
      const res = await createData("news_letter/create_subscriber", formData);
      if (res.status === 200) {
        displayToastSuccess(t("footer.subscribed"));
        setEmail("");
      }
    } catch (err: any) {
      // console.log(err?.response);
      displayToastErrorApi(err?.response?.data?.errors?.email[0]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack spacing={4}>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          color: "#BAE4FE",
          fontSize: {
            mobile: "1.25rem",
            md: "1.5rem",
            xl: "1.75rem",
          },
          fontWeight: "400",
        }}
      >
        {t("footer.follow")}
      </Typography>
      <Stack direction="row" justifyContent={"center"}>
        <Box
          sx={{
            height: {
              mobile: "50px",
              md: "55px",
              xl: "65px",
            },
            backgroundColor: "#2c1845",
            width: {
              mobile: "320px",
              md: "400px",
              xl: "530px",
            },
            display: "flex",
            justifyContent: "space-between",
            borderRadius: "6px",
          }}
        >
          <InputBase
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            sx={{
              height: "100%",
              fontSize: {
                mobile: "1rem",
                md: "1.5rem",
              },
              paddingLeft: "2rem",
              color: "secondary.main",
              "&.Mui-focused": {
                color: "secondary.main",
                outline: "none",
                border: "none",
              },
              "&::before": {
                display: "none",
              },
              "&::after": {
                display: "none",
              },
            }}
            placeholder={t("footer.email")}
          />
          <Button
            disabled={loading}
            onClick={() => {
              subscribe(email);
            }}
            endIcon={
              loading && <CircularProgress size={20} color="secondary" />
            }
            variant="contained"
            disableElevation
            sx={{
              position: "relative",
              width: {
                mobile: "120px",
                md: "190px",
              },
              fontSize: {
                mobile: "1rem",
                md: "1.5rem",
              },
              backgroundColor: "transparent",
              backgroundImage:
                "linear-gradient(180deg, #3781C2 0%, #3DB9EC 100%)",
              "&:hover": {
                backgroundColor: "transparent",
              },
            }}
          >
            {t("footer.subscribe")}
          </Button>
        </Box>
      </Stack>
    </Stack>
  );
};

export default FollowUs;
