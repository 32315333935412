// import { TextField, styled } from "@mui/material";

// interface MainTextFieldProps {
//   background?: string;
// }

// const MainTextField = styled(TextField, {
//   shouldForwardProp: (prop) => prop !== "background",
//   overridesResolver: (props, styles) => [
//     styles.root,
//     props.background && {
//       backgroundColor: props.background, // Use backgroundColor instead of background
//     },
//   ],
// })<MainTextFieldProps>({
//   background: "#81cefc38",
//   borderRadius: "12px",
//   color: "white",
//   "& .MuiOutlinedInput-root": {
//     color: "white",
//     borderRadius: "12px",
//     "&::hover": {
//       borderColor: "rgb(88 135 233 / 40%)",
//     },
//   },
//   "& .MuiInputBase-input": {
//     padding: "26px 14px",
//   },
//   "& .MuiInputLabel-root": {
//     color: "#ECECEC",
//     transform: "translate(14px, 26px) scale(1)",
//   },
//   "& .MuiInputLabel-root.Mui-focused": {
//     color: "#81CEFC",
//     transform: "translate(14px, -10px) scale(0.75)",
//   },
// });
// export default MainTextField;

import { TextField, styled, useMediaQuery } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import PhoneInput from "react-phone-input-2";

interface MainTextFieldProps {
  background?: string;
}

const CustomTextField = styled(TextField)<MainTextFieldProps>(
  ({ background }) => ({
    borderRadius: "12px",
    color: "white",
    "& .MuiOutlinedInput-root": {
      background: background || "#81cefc38",
      color: "white",
      borderRadius: "12px",
      // border: "1px solid black",

      "&:hover": {
        borderColor: "rgb(88, 135, 233, 0.4)",
      },
    },
    "& .MuiInputBase-input": {
      padding: "26px 14px",
      "@media (max-width: 600px)": {
        padding: "16px 14px",
      },
    },
    "& .MuiInputLabel-root": {
      color: "#ECECEC",
      // transform: "translate(14px, 26px) scale(1)",
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: "#81CEFC",
      // transform: "translate(14px, -10px) scale(0.75)",
    },
    "&.Mui-disabled": {
      color: "green",
    },
    "& .Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
  })
);

interface Props {
  name: string;
  control: any;
  background?: string;
  color?: string;
  label?: string;
  multiline?: boolean;
  placeholder?: string;
  disabled?: boolean;
  type?: string;
  error?: any;
}

const MainTextField = ({
  control,
  name,
  background,
  color,
  label,
  multiline,
  placeholder,
  disabled,
  type,
  error,
}: Props) => {
  const isMobile = useMediaQuery("(max-width:650px)");

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field, fieldState: { error } }) => (
        <CustomTextField
          autoSave="false"
          InputProps={{
            placeholder: placeholder,
          }}
          error={!!error}
          autoComplete="off"
          background={background || "#e8d6ff40"}
          color={error ? "error" : "secondary"}
          label={label}
          helperText={error ? error.message : ""}
          variant="outlined"
          {...field}
          multiline={multiline}
          minRows={multiline ? (isMobile ? 4 : 8) : 1}
          disabled={disabled}
          type={type}
          sx={{
            "& .MuiFormHelperText-root": {
              color: "error.main",
            },
          }}
        />
      )}
    />
  );
};

export default MainTextField;
