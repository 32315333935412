import React, { useEffect, useState } from "react";
import {
  MenuItem,
  ClickAwayListener,
  Typography,
  TextField,
} from "@mui/material";
import { Controller } from "react-hook-form";
import { country, genre_id, sort_by } from "./FormMenuData";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";

const CustomFormSelect = styled(TextField)({
  borderRadius: "12px",
  color: "#E8D6FF",
  flex: "1",
  "& .MuiInputBase-root:hover:before": {
    borderBottom: "2px solid #bb96ea",
  },
  "& .MuiTextField-root": {
    "&.Mui-disabled": {
      borderBottomStyle: "solid",
      color: "#E8D6FF",
    },
    "&.Mui-disabled:before": {
      borderBottomStyle: "solid",
    },
  },

  "& .MuiMenu-paper": {
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    top: "274px",
    marginTop: "10px",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  "& .MuiInput-input": {
    color: "white",
    opacity: "0.5",
  },
  "& .MuiInput-root:before": {
    borderBottomColor: "#E8D6FF",
  },
  "& .MuiInput-root:after": {
    borderBottomColor: "#E8D6FF",
  },
  "& .MuiInputLabel-root": {
    color: "#E8D6FF",
    opacity: "0.5",
  },
  "& .Mui-focused": {
    color: "#E8D6FF",
  },
  "& .MuiTypography-root": {
    cursor: "pointer",
    fontSize: "1rem",
    fontWeight: "400",
    color: "#8768AC",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      bottom: "-5px",
      left: "50%",
      width: "90%",
      transform: "translateX(-50%)",
      height: "1px",
      backgroundColor: "#7427c924",
    },
  },
  "& .MuiPopper-root": {
    borderRadius: "12px",
    backgroundColor: "#FFFFFF",
    top: "274px",
    marginTop: "10px",
  },
});

interface Props {
  menuType: string;
  label: string;
  control?: any;
  name: string;
  readOnly?: boolean;
  disabled?: boolean;
  menuItems?: any;
}
type Menu = {
  id: number;
  title: {
    ar: string;
    en: string;
  };
};

const FormMenu = ({ menuType, label, control, name, menuItems }: Props) => {
  const [showMenu, setShowMenu] = useState<boolean>(false);

  const handleCloseMenu = () => {
    setShowMenu(false);
  };

  const [menuCheck, setMenuCheck] = useState<any>(
    menuType === "country"
      ? country
      : menuType === "sort_by"
      ? sort_by
      : menuType === "genre_id"
      ? genre_id
      : country
  );

  //   check menu type and return corresponding array
  // const menuCheck =
  //   menuType === "country"
  //     ? country
  //     : menuType === "sort_by"
  //     ? sort_by
  //     : menuType === "genre_id"
  //     ? genre_id
  //     : country;

  // Detect Language
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const filterState = useSelector((state: any) => state.filter);

  useEffect(() => {
    if (
      menuType === "genre_id" &&
      filterState?.arabicFilters &&
      filterState?.englishFilters
    ) {
      let newMenu = filterState?.arabicFilters
        .concat(filterState?.englishFilters)
        .map((item: any) => {
          return {
            id: item.id,
            title: {
              ar: item.name_ar,
              en: item.name_en,
            },
          };
        });
      setMenuCheck(newMenu);
    }
  }, [filterState]);

  return (
    <ClickAwayListener onClickAway={handleCloseMenu}>
      <>
        <Controller
          defaultValue=""
          name={name}
          control={control}
          render={({ field, fieldState: { error, isTouched } }) => (
            <CustomFormSelect
              error={!!error}
              color={error ? "error" : "secondary"}
              helperText={error ? error.message : ""}
              variant="standard"
              id="select"
              label={label}
              select
              sx={{
                "&.MuiTextField-root": {
                  "&.Mui-focused": {
                    color: "#ffffff",
                  },
                  "& .MuiTypography-root": {
                    color: "#ffffff",
                    fontSize: "1.25 rem",
                  },
                  "& .MuiInput-input": {
                    opacity: "1",
                  },
                },
              }}
              SelectProps={{
                MenuProps: {
                  sx: {
                    "& .MuiMenuItem-root": {
                      "&::after": {
                        backgroundColor: "#7427c90d",
                      },
                    },
                    "& .MuiMenu-paper": {
                      borderRadius: "12px",
                      backgroundColor: "#FFFFFF",
                      marginTop: "10px",
                      maxHeight: "300px",
                      "&::-webkit-scrollbar": {
                        width: "0px",
                      },
                    },
                    "& .MuiTypography-root": {
                      cursor: "pointer",
                      fontSize: "1.5rem",
                      fontWeight: "400",
                      color: "#755798",
                      position: "relative",
                    },
                  },
                },
              }}
              {...field}
            >
              {menuCheck?.map((item: Menu, index: number) => {
                return (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    sx={{
                      "&:after": {
                        content: "''",
                        position: "absolute",
                        bottom: "3px",
                        left: "50%",
                        width: "90%",
                        transform: "translateX(-50%)",
                        height: "1px",
                        backgroundColor: "#7427c924",
                        display:
                          index === menuCheck.length - 1 ? "none" : "block",
                      },
                    }}
                  >
                    <Typography
                      onClick={() => {
                        field.onChange(item.id);
                      }}
                      key={item.id}
                      sx={{
                        color: "#755798",
                        "&::after": {
                          display:
                            index === menuCheck.length - 1 ? "none" : "block",
                        },
                      }}
                    >
                      {language === "ar" ? item.title.ar : item.title.en}
                    </Typography>
                  </MenuItem>
                );
              })}
            </CustomFormSelect>
          )}
        />
      </>

      {/* </Select> */}
    </ClickAwayListener>
  );
};

export default React.memo(FormMenu);
