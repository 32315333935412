import { Box, CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import useWhishList from "../../../Hooks/useWhishList";
import React from "react";

type Props = {
  id: string;
  type: string;

  is_favorite?: boolean;
};

const FavIcon = ({ id, type, is_favorite }: Props) => {
  const { handleAddOrRemoveFromFavorite, loading, isFavorite } = useWhishList(
    id,
    type,
    is_favorite
  );
  return (
    <Box
      onClick={handleAddOrRemoveFromFavorite}
      initial={{ scale: 1 }}
      whileTap={{ scale: 0.9 }}
      component={motion.div}
      sx={{
        position: "absolute",
        top: "20px",
        left: "18px",
        zIndex: "1",
      }}
    >
      {loading ? (
        <CircularProgress size={25} />
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26.113"
          height="24.474"
          viewBox="0 0 26.113 24.474"
        >
          <motion.path
            id="Icon_material-favorite"
            data-name="Icon material-favorite"
            d="M15.057,26.624l-1.748-1.591C7.1,19.4,3,15.688,3,11.131A6.567,6.567,0,0,1,9.631,4.5a7.22,7.22,0,0,1,5.425,2.52A7.22,7.22,0,0,1,20.482,4.5a6.567,6.567,0,0,1,6.631,6.631c0,4.557-4.1,8.271-10.308,13.913Z"
            transform="translate(-2 -3.5)"
            stroke="#bb84ff"
            strokeWidth="2"
            fill={isFavorite ? "#bb84ff" : "transparent"}
          />
        </svg>
      )}
    </Box>
  );
};

export default React.memo(FavIcon);
