import { yupResolver } from "@hookform/resolvers/yup";
import { CircularProgress, Stack } from "@mui/material";
import { isValidPhoneNumber } from "libphonenumber-js";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import useRegisterFormHelper from "../../../Helpers/AuthFunctions/useRegisterFormHelper";
import StyledButton from "../../StyledComponents/StyledButton/StyledButton";
import MainTextField from "../MainTextField/MainTextField";
import PhoneInput from "../PhoneInput/PhoneInput";

const ProfileForm = ({ data }: any) => {
  const { RegisterFromHelper } = useRegisterFormHelper();

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  // Language Handler
  const { t } = useTranslation();
  const { name, email, mobile, username, client_note } = data;

  const validationSchema = yup.object().shape({
    name: yup.string().required(t("validation.required")),
    email: yup
      .string()
      .required(t("validation.required"))
      .email(t("validation.email")),
    mobile: yup
      .string()
      .required(t("validation.required"))
      // .test("len", t("validation.mobile"), (val) => {
      //   val = val?.replace(/\s/g, "");
      //   return val?.trim().length > 8;
      // })
      .test("isValid", (val) => {
        return isValidPhoneNumber(val);
      }),
    username: yup.string().required(t("validation.required")),

    client_note: yup.string().notRequired(),
  });
  const {
    handleSubmit,
    register,
    setError,
    reset,
    control,

    formState: { errors, touchedFields },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name,
      email,
      mobile,
      username,
      client_note,
    },
  });
  // fetch default values from API
  const handleProfileFormSubmit = async (data: any, setError: any) => {
    const formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    RegisterFromHelper(data, "profile/update", setError, setLoading);
  };
  return (
    <>
      <Stack
        component={"form"}
        spacing={2}
        sx={{
          opacity: "0.75",
        }}
        onChange={() => {
          setSubmitting(true);
        }}
      >
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          label={t("profile.profileForm.name")}
          name="name"
          control={control}
        />
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          label={t("profile.profileForm.email")}
          name="email"
          control={control}
        />
        <PhoneInput
          control={control}
          name="mobile"
          label={t("profile.profileForm.mobile")}
        />
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          label={t("profile.profileForm.userName")}
          name="username"
          control={control}
        />
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          label={t("profile.profileForm.password")}
          name="password"
          control={control}
          type="password"
        />
        <MainTextField
          background="#CEAFF542"
          color="#ECECEC"
          label={t("profile.profileForm.client_note")}
          name="client_note"
          control={control}
          type="text"
        />
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignSelf: "flex-end",
        }}
      >
        <StyledButton
          sx={{
            backgroundColor: "#A47FD1",
            width: "180px",
          }}
          variant="contained"
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} />}
          onClick={handleSubmit(handleProfileFormSubmit)}
        >
          {t("profile.profileForm.save")}
        </StyledButton>
      </Stack>
    </>
  );
};

export default ProfileForm;
