import { Button, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Price } from "../../../Types/Types";
import { addToCartModalActions } from "../../../store/addToCart-slice";
import { StyledIconButton } from "../../StyledComponents/StyledButton/StyledButton";

type Props = {
  id: string;
  type: string;
  price: Price;
  is_available: boolean;
};

const AddToCartButton = ({ id, type, price, is_available }: Props) => {
  const matches = useMediaQuery("(max-width:768px)");
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cookies = new Cookies();
  const navigate = useNavigate();
  // console.log("test",cookies);
  
  const openAddToCartModal = () => {
    if (!cookies.get("token")) {
      navigate("/login");
    } else {
      let info = {
        movieId: id,
        type: type,
        price: price,
        is_available: is_available,
      };
      dispatch(addToCartModalActions.openAddToCartModal(info));
    }
  };

  return (
    <>
      {matches ? (
        <StyledIconButton
          onClick={openAddToCartModal}
          sx={{
            background: "#E69D0B",
            color: "white",
            "& svg": {
              objectFit: "contain",
              "& path": { fill: "white" },
            },
            "&:hover": {
              background: "#a16b00",
            },
          }}
          variant="contained"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="34.733"
            height="32.563"
            viewBox="0 0 34.733 32.563"
          >
            <path
              id="Icon_metro-cart"
              data-name="Icon metro-cart"
              d="M15.6,33.162a3.256,3.256,0,1,1-3.256-3.256A3.256,3.256,0,0,1,15.6,33.162Zm21.708,0a3.256,3.256,0,1,1-3.256-3.256A3.256,3.256,0,0,1,37.3,33.162Zm0-14.11V6.027H11.254A2.171,2.171,0,0,0,9.083,3.856H2.571V6.027H6.912L8.543,20a4.341,4.341,0,0,0,2.711,7.732H37.3V25.564H11.254a2.171,2.171,0,0,1-2.171-2.171c0-.007,0-.015,0-.022L37.3,19.052Z"
              transform="translate(-2.571 -3.856)"
            />
          </svg>
        </StyledIconButton>
      ) : (
        <Button
          onClick={openAddToCartModal}
          variant="contained"
          sx={{
            fontSize: {
              mobile: "1rem",
              lg: "1.25rem",
            },
            background: "#E69D0B",
            color: "white",
            width: "150px",
            height: {
              mobile: "50px",
              md: "60px",
              lg: "75px",
            },
            borderRadius: "10px",
            fontWeight: "600 !important",
            "&:hover": {
              background: "#a16b00",
            },
          }}
          disableElevation
        >
          {t("buyBig")}{" "}
        </Button>
      )}
    </>
  );
};

export default AddToCartButton;
